
<div class="container-menu">
  <div class="row">
    <div class="col-9">
      <h2 class="color-white">Menu</h2>
    </div>
    <div class="col-3">
      <button (click)="closemenu()" mat-icon-button  class="color-white"  >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

<mat-divider></mat-divider>
  <mat-list class="container-menu__lista" role="list">
    <mat-list-item *ngFor="let item of listMenu" class="container-menu__lista__items">
      <a [routerLink]="item.patch" class="container-menu__lista__items__link" [routerLinkActive]="'container-menu__lista__items__link-active'">
        <mat-icon>{{item.icon}}</mat-icon>
        {{item.label}}
      </a>
    </mat-list-item>

  </mat-list>
  <small class="d-block text-center mt-4 color-white">Adminstrador</small>
  <small class="d-block text-center mt-1 color-white">{{version}}</small>
</div>


