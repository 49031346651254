import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from "./core/components/components.module";
import { MaterialAngularModule } from './material-angular.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorInterceptor } from './core/interceptors/interceptor.interceptor';
import { IConfig, NgxMaskModule, } from 'ngx-mask';
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
@NgModule({
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorInterceptor,
      multi: true,
    },

  ],
  bootstrap: [AppComponent],
  imports: [
    NgxMaskModule.forRoot(maskConfigFunction),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ComponentsModule,
    MaterialAngularModule,
  ]
})
export class AppModule {

}
