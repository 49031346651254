import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
@Injectable({
  providedIn: 'root',
})

export class Helperclass {

  constructor(
    private router: Router,
    private location: Location
  ) {

  }

  back() {
    this.location.back();
  }

  goRouter(url: string, params = {}): void {
    this.router.navigate([`/${url}`], { queryParams: { ...params } });
  }

  showalert(text: string, html: string, showCancelButton = true): Promise<any> {

    return Swal.fire({
      title: text,
      html: html,
      showCancelButton: showCancelButton,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    });
  }

  dateConverter = (date: string, formate = 'YYYY-MM-DD HH:mm:ss'): string | Date => {
    return dayjs(date).locale('en').format(formate);
  };

  isAdmin() {
    const dataAdmin = JSON.parse(localStorage.getItem('dataMotor')!);
    if (dataAdmin.admin === 1) {
      return true;
    } else {
      this.showalert('Error!!', 'No tiene permisos para esta acción', false);
      return false;
    }
  }
  isAdminAccion() {
    const dataAdmin = JSON.parse(localStorage.getItem('dataMotor')!);
    if (dataAdmin.admin === 1) {
      return true;
    } else {

      return false;
    }
  }

  detectarvalorbuscatr(array, item) {
    const index = array.findIndex(cus => cus === item);
    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  }

  obtenerObjetosConIds(objetos: any, ids: number[]): any[] {

    return objetos.filter(objeto => ids.includes(objeto.id));
  }
  obtenerObjetosConIdsSepomex(objetos: any, ids: number[]): any[] {
    return objetos.filter(objeto => ids.includes(objeto.id_cat_sepomex));
  }
}
