<div class="mr-pages container-fluid">
  <div class="row">

    <div class="col-12 alert alert-danger" role="alert">
      {{textAlert}}
    </div>
    <div class="col-12 background-white mt-4 max-hide-overflow">
      <app-table [showPaginator]="false" [columns]="header" [length]="totalRegistros" [dataSource]="body">

      </app-table>
    </div>
    <div class="col-12 d-flex justify-content-center background-white mt-4">
      <button (click)="close()" class="btn btn-primary">
        Aceptar
      </button>
    </div>
  </div>
</div>