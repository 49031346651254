import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, firstValueFrom } from 'rxjs';
import { formOfertaJson } from '../models/ofetas.model';
import { environment } from 'src/environments/environment';
import { payloadGeneric } from '../models/generig.mode';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  IsSpinner$ = new Subject<boolean>();
  IsLogin$ = new Subject<boolean>();
  IsCatalog$ = new Subject<boolean>();
  url = environment.urlService;
  urlInventarioDrupal = environment.urlInventarioDat;
  tokenInventario = environment.tokenInvenatarioDat;
  urlInventarioDatGet = environment.urlInventarioDatGet
  filtros: payloadGeneric;
  constructor(
    private http: HttpClient,
  ) { }

  getFormoferta() {
    return this.http.get<formOfertaJson>('../assets/json/form/form-oferta.json');
  }
  getEquipos(canal, region) {
    const paramas: any = {
      canal,
      region,
    }
    return this.http.get<formOfertaJson>('http://184.72.154.197/web/api/v2/equipos', { params: { ...paramas } });
  }
  generalppost(body) {
    return firstValueFrom(this.http.post<any>(this.url, body));
  }
  generalurl(url, body, size) {
    const header = new HttpHeaders({ "Content-Length": size, "Content-Type": "text/csv" });
    return firstValueFrom(this.http.put<any>(url, body, { headers: header }));
  }

  cargarInventarioDat(filtros) {
    return firstValueFrom(this.http.post<any>(this.urlInventarioDrupal, filtros, { headers: { 'Token': this.tokenInventario } }));
  }
  getInventarioDat() {
    return firstValueFrom(this.http.get<any>(this.urlInventarioDatGet));
  }

}
