import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilityService } from './core/services/utility.service';
import { Helperclass } from './core/class/helperclass';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Admintrador-Ofertas-Promociones';
  showFiller = false;
  showSpinner = false;
  loginIs=false;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private UtilityService:UtilityService,
  ){
    this.UtilityService.IsSpinner$.subscribe(data=>this.showSpinner = data);
    this.UtilityService.IsLogin$.subscribe(data=>this.loginIs = data);
    iconRegistry
    .addSvgIcon('ic-eye', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/eye.svg'))
    .addSvgIcon('ic-close', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/close-line.svg'))
    .addSvgIcon('ic-trash', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/trash.svg'))
    .addSvgIcon('ic-check', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Vector.svg'))
    .addSvgIcon('ic-actualizar', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/actualizar.svg'))
    .addSvgIcon('ic-edit', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/edit.svg'));

  }
  outputlogin(option){
    this.loginIs = option;
  }



}
