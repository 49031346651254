<div class="container-fluid background-blue container-head" >
  <div class="row "  *ngIf="isLogin">
    <div class="col-6">
      <button (click)="openmenu()" mat-icon-button *ngIf="isLogin"  class="color-white"  >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="col-6 ">
      <div class="row justify-content-end">
        <div class="col-2">
          <!--<button mat-button class="color-white"><mat-icon>person</mat-icon> Mi perfil</button>-->
        </div>
        <div class="col-4">
          <button (click)="exit()" mat-button class="color-white"><mat-icon>logout</mat-icon> Cerrar sesion</button>
        </div>
      </div>
    </div>
  </div>
</div>
