import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeadComponent } from './head/head.component';
import { MaterialAngularModule } from 'src/app/material-angular.module';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { TableComponent } from './table/table.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorlistComponent } from './errorlist/errorlist.component';
import { ErrorlistInventComponent } from './errorlistInevent/errorlistInvent.component';

@NgModule({
  declarations: [
    HeadComponent,
    MenuComponent,
    TableComponent,
    SpinnerComponent,
    ErrorlistComponent,
    ErrorlistInventComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialAngularModule,
    RouterModule,
    MaterialAngularModule,
    FormlyModule.forRoot(),
    ReactiveFormsModule,
    FormlyMaterialModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatStepperModule,
    MatDialogModule,
    MatTooltipModule,

  ],
  exports: [
    SpinnerComponent,
    TableComponent,
    MenuComponent,
    HeadComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialAngularModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatStepperModule,
    MatDialogModule,
    MatTooltipModule
  ]
})
export class ComponentsModule { }
