import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuService } from '../../services/menu.service';
import { menuItemsInterface } from '../../models/menu.model';
import { UtilityService } from '@app/core/services/utility.service';

@Component({
  selector: 'motor-calculo-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Output() closeMenu = new EventEmitter<boolean>();
  version = environment.version
  listMenu: menuItemsInterface[] = [];


  constructor(private MenuService: MenuService, private UtilityService: UtilityService) {

    this.UtilityService.IsLogin$.subscribe(data => {
      // console.log(data);
      if (data) {
        this.getmenu();
      }
    })
  }
  ngOnInit(): void {
    this.getmenu();
  }
  getmenu() {
    this.listMenu = [];
    const dataUser = JSON.parse(localStorage.getItem('dataMotor')!);
    //console.log("TCL: MenuComponent -> getmenu -> ", localStorage.getItem('dataMotor'));
    if (dataUser) {
      this.MenuService.optionsmenu().subscribe(data => {
        let menu: any[] = [];
        if (dataUser.admin === 1 || dataUser.admin === 0) {
          menu = data.menu.filter((item) => {
            return item;
          });
        } else if (dataUser.admin === 3) {
          menu = data.menucampanas.filter((item) => {
            return item;
          });
        }

        // console.log(menu);
        this.listMenu = menu;
      });
    }

  }
  closemenu() {
    this.closeMenu.emit(false);
  }
}
