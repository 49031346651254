import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Helperclass } from '../../class/helperclass';
import { UtilityService } from '../../services/utility.service';
import { CatalogsService } from '../../services/catalogs.service';

@Component({
  selector: 'motor-calculo-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.scss']
})
export class HeadComponent {
  @Input() isLogin = true;
  @Output() output = new EventEmitter<boolean>();
  @Output() openMenu = new EventEmitter<boolean>();
  constructor(
    private Helperclass: Helperclass,
    private UtilityService: UtilityService,
    private CatalogsService: CatalogsService
  ) {
    this.UtilityService.IsLogin$.subscribe(data => this.isLogin = data);
    this.buscarConExpiracion();
    this.UtilityService.IsCatalog$.subscribe(data => {
      if (data) {
        this.cargacatalogos();
      }
    })
  }

  buscarConExpiracion(): void {
    if (localStorage.getItem('expirationMotor')) {
      let data = localStorage.getItem('expirationMotor');
      data = JSON.parse(data || '{}');

      if (data) {
        // Obtener la hora actual en la zona horaria de la Ciudad de México
        const fecha: Date = new Date();
        const fechaExpiracion: Date = new Date(data);

        if (fecha.getTime() - fechaExpiracion.getTime() > 60 * 60 * 1000) { // una hora en milisegundos
          this.isLogin = false;
          localStorage.removeItem('tokenMotor');
          localStorage.removeItem('expirationMotor');
          localStorage.removeItem('dataMotor');
          localStorage.removeItem('Idusuario');
          this.Helperclass.goRouter('/');
        } else {
          this.isLogin = true;
          this.cargacatalogos();
        }
      }
    } else {
      this.Helperclass.goRouter('/');
    }
  }

  async exit() {
    try {
      const response = await this.Helperclass.showalert('Atención!', '¿Seguro que quiere salir?');
      if (response.isConfirmed) {
        this.UtilityService.IsLogin$.next(false);
        localStorage.removeItem('tokenMotor');
        localStorage.removeItem('expirationMotor');
        localStorage.removeItem('dataMotor');
        localStorage.removeItem('Idusuario');
        this.isLogin = false;
        this.output.emit(false);
        // this.Helperclass.goRouter('/');
        window.location.reload();
      }
    } catch (error) {

    }
  }

  async cargacatalogos() {
    this.UtilityService.IsSpinner$.next(true);
    await this.CatalogsService.setCatalogos();
    this.UtilityService.IsSpinner$.next(false);
  }

  openmenu() {
    this.openMenu.emit(true);
  }
}
