export const environment = {
  production: true,
  version: 'v.q.0.3.0.5',
  login: 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa/',
  urlEquipos: 'https://dedm8q2o4wuuo.cloudfront.net/web/equipos-v2-detalles',
  apikeyLogin: '8U9ezbHvfw5G4FX0ZnXvnRE0z5KKN5C5XaEOCna5',
  apikey: '8U9ezbHvfw5G4FX0ZnXvnRE0z5KKN5C5XaEOCna5',
  urlService: 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa/administradorOfertas',
  keyRecapcha: '',
  nameurlLogin: 'loginMotorAdminPre',
  urlInventarioDat: 'https://dedm8q2o4wuuo.cloudfront.net/web/add-dat',
  urlInventarioDatGet: 'https://dedm8q2o4wuuo.cloudfront.net/web/check-dat?_format=json',
  tokenInvenatarioDat: '3e9d7b2a5f6c',
  urlogin: {
    url: '',
    apikey: ''
  }
};

