import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { headErrorsList } from '@app/core/data/data';
import { listErrors } from '@app/core/models/campana-usuario';

@Component({
  selector: 'motor-calculo-errorlist',
  templateUrl: './errorlist.component.html',
  styleUrls: ['./errorlist.component.scss']
})
export class ErrorlistComponent {
  header = headErrorsList
  body: any[] = [];
  totalRegistros = 0;
  textAlert = ' A continuación te mostramos los errores encontrados en la información';
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any = []
  ) {
    console.log(data);
    if (this.data?.errores) {
      this.body = this.data?.errores;
      this.totalRegistros = this.data?.totalRegistros;
      this.textAlert = this.textAlert + ', el total de registros agregados es: ' + this.totalRegistros;
    } else {
      this.body = this.data;
    }


  }

  close() {
    this.dialog.closeAll();
  }
}
