import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { region, responseRegion } from '../models/region.model';
import { nivelRiesgo, Nivelriesgo } from '../models/ofetas.model';
import { payloadGeneric } from '../models/generig.mode';
import { canal } from '../models/canal.model';
import { tipocliente } from '../models/tipocliente.model';
import { responseEstado } from '../models/estados.model';
import { responseCiudad } from '../models/ciudad.model';
import { responsecondicionespago, responseObject } from '../models/condicionespago.mode';
import { ResponseConstDrupal } from '../models/equipos.model';
import { Campana, responseCampanaCatalog } from '../models/campana';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {
  url = environment.urlService;
  filtros: payloadGeneric;
  private dataRegionSubject: BehaviorSubject<region[] | undefined> = new BehaviorSubject({} as region[] | undefined);
  public readonly dataRegion: Observable<region[] | undefined> = this.dataRegionSubject.asObservable();

  private dataNivelRiesgoSubject: BehaviorSubject<nivelRiesgo[] | undefined> = new BehaviorSubject({} as nivelRiesgo[] | undefined);
  public readonly dataNivelRiesgo: Observable<nivelRiesgo[] | undefined> = this.dataNivelRiesgoSubject.asObservable();

  private dataCanalSubject: BehaviorSubject<canal[] | undefined> = new BehaviorSubject({} as canal[] | undefined);
  public readonly dataCanal: Observable<canal[] | undefined> = this.dataCanalSubject.asObservable();

  private dataTipoClienteSubject: BehaviorSubject<tipocliente[] | undefined> = new BehaviorSubject({} as tipocliente[] | undefined);
  public readonly dataTipocliente: Observable<tipocliente[] | undefined> = this.dataTipoClienteSubject.asObservable();

  private dataCampanasSubject: BehaviorSubject<Campana[] | undefined> = new BehaviorSubject({} as Campana[] | undefined);
  public readonly dataCampana: Observable<Campana[] | undefined> = this.dataCampanasSubject.asObservable();

  private dataFzaVentasSubject: BehaviorSubject<any[] | undefined> = new BehaviorSubject({} as any[] | undefined);
  public readonly dataFzaVentas: Observable<any[] | undefined> = this.dataFzaVentasSubject.asObservable();
  private dataSubFzaVentasSubject: BehaviorSubject<any[] | undefined> = new BehaviorSubject({} as any[] | undefined);
  public readonly dataSubFzaVentas: Observable<any[] | undefined> = this.dataSubFzaVentasSubject.asObservable();

  private dataPerfilDatSubject: BehaviorSubject<any[] | undefined> = new BehaviorSubject({} as any[] | undefined);
  public readonly dataPerfilDat: Observable<any[] | undefined> = this.dataPerfilDatSubject.asObservable();

  private dataTipoSubFuerzaSubject: BehaviorSubject<any[] | undefined> = new BehaviorSubject({} as any[] | undefined);
  public readonly dataTipoSubFuerza: Observable<any[] | undefined> = this.dataTipoSubFuerzaSubject.asObservable();


  private dataTipoVentaSubject: BehaviorSubject<any[] | undefined> = new BehaviorSubject({} as any[] | undefined);
  public readonly dataTipoVenta: Observable<any[] | undefined> = this.dataTipoVentaSubject.asObservable();



  constructor(
    private http: HttpClient
  ) { }

  public async setCatalogRegion() {
    try {
      let response: any;
      this.filtros.payload = {
        ruta: 'getallregionCatalog'
      };
      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataRegionSubject.next(response.responseList);
    } catch (error) {
      //console.log(error);
    }
  }

  public async setCatalogTipoClienten() {
    try {
      let response: any;
      this.filtros.payload = {
        ruta: 'getalltipoclienteCatalog'
      };
      response = await firstValueFrom(this.http.post<any>(this.url, this.filtros));
      this.dataTipoClienteSubject.next(response.responseList);
    } catch (error) {
      //console.log(error);
    }
  }

  public async setCanal() {
    try {
      let response: any;
      this.filtros.payload = {
        ruta: 'getallCanalCatalog'
      };
      response = await firstValueFrom(this.http.post<any>(this.url, this.filtros));
      this.dataCanalSubject.next(response.responseList);
    } catch (error) {
      //console.log(error);
    }
  }


  public async setCatalogNivelRiesgo() {
    let response: any;
    this.filtros.payload = {
      ruta: 'getallnivelriesgoCatalog'
    };
    response = await firstValueFrom(this.http.post<any>(this.url, this.filtros));
    this.dataNivelRiesgoSubject.next(response.responseList);
  }

  public async setCatalogos(): Promise<boolean> {
    this.filtros = {
      token: 'adsf'
    }
    let response: any;

    try {
      // region ;
      this.filtros.payload = {
        ruta: 'getallregionCatalog'
      };
      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataRegionSubject.next(response.responseList);
      // nivel riesgo ;
      this.filtros.payload = {
        ruta: 'getallnivelriesgoCatalog'
      };
      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataNivelRiesgoSubject.next(response.responseList);
      // Tipo Cliente ;
      this.filtros.payload = {
        ruta: 'getalltipoclienteCatalog'
      };
      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataTipoClienteSubject.next(response.responseList);
      // canal ;
      this.filtros.payload = {
        ruta: 'getallCanalCatalog'
      };

      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataCanalSubject.next(response.responseList);

      const { responseList } = await this.getCampanasAll(1);
      this.dataCampanasSubject.next(responseList);
      this.filtros.payload = {
        ruta: 'catalogFuerzaVentas'
      };
      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataFzaVentasSubject.next(response.responseList);

      this.filtros.payload = {
        ruta: 'getCatalogSubFurzas'
      };
      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataSubFzaVentasSubject.next(response.responseList);

      this.filtros.payload = {
        ruta: 'getCatalogPerfilDat'
      };
      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataPerfilDatSubject.next(response.responseList);

      this.filtros.payload = {
        ruta: 'getCatalogTipoSubventa'
      };
      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataTipoSubFuerzaSubject.next(response.responseList);
      this.filtros.payload = {
        ruta: 'getCatalogTipoVenta'
      };
      response = await firstValueFrom(this.http.post<responseRegion>(this.url, this.filtros));
      this.dataTipoVentaSubject.next(response.responseList);




      return true;



    } catch (error) {
      //console.log(error);
      return false;
    }
  }

  public async loadCatalogCampanas() {
    try {
      const { responseList } = await this.getCampanasAll(1);
      this.dataCampanasSubject.next(responseList);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public async getEquipos(canal, region): Promise<ResponseConstDrupal[]> {

    const headers = new HttpHeaders()
      .set('Token', localStorage.getItem('tokenMotor')!)
      .set('Idusuario', localStorage.getItem('Idusuario')!)
    //?_format=json&page=0&limit=70&canal=1&region=9
    return firstValueFrom(this.http.get<ResponseConstDrupal[]>(environment.urlEquipos + '?_format=json&canal=' + canal + '&region=' + region, { headers: headers }));
  }

  public getEstados(listado: number[]) {

    this.filtros.payload = {
      ruta: 'getestadosbyregion',
      listregiones: listado
    };
    return firstValueFrom(this.http.post<responseEstado>(this.url, this.filtros));
  }

  public getEstadosall() {
    this.filtros.payload = {
      ruta: 'getestadossepomex',
    };
    return firstValueFrom(this.http.post<any>(this.url, this.filtros));
  }

  public getCiudades(listado: number[]) {

    this.filtros.payload = {
      ruta: 'getciudades',
      listestados: listado
    };
    return firstValueFrom(this.http.post<responseCiudad>(this.url, this.filtros));
  }

  public getcondicionespago(nivel: nivelRiesgo) {
    this.filtros.payload = {
      ruta: 'getcondicionesbynivel',
      nivelriesgos: nivel
    };
    return firstValueFrom(this.http.post<responsecondicionespago>(this.url, this.filtros));
  }
  public getcondicionespagoAll() {
    this.filtros.payload = {
      ruta: 'getcondicionesAllCatalog'
    };
    return firstValueFrom(this.http.post<responsecondicionespago>(this.url, this.filtros));
  }

  public getCampanasAll(status: number) {
    this.filtros.payload = {
      ruta: 'getCatalogoCamapana',
      status
    };
    return firstValueFrom(this.http.post<responseCampanaCatalog>(this.url, this.filtros));
  }

}
