<motor-calculo-head (output)="outputlogin($event)" (openMenu)="drawer.toggle()"></motor-calculo-head>


<mat-drawer-container class="container-general contaner-fluid background-gris1" autosize>
  <mat-drawer #drawer class="container-general__menu" mode="side">
    <motor-calculo-menu (closeMenu)="drawer.toggle()"></motor-calculo-menu>
  </mat-drawer>

  <div class="example-sidenav-content ">
    <router-outlet></router-outlet>
  </div>

</mat-drawer-container>
<motor-calculo-spinner *ngIf="showSpinner"></motor-calculo-spinner>
